import React, { Component } from 'react'

export default function lazyRequire(importComponent) {
	class lazyRequire extends Component {
		constructor(props) {
			super(props)

			this.state = {
				component: null,
			}
		}

		async componentDidMount() {
			const { default: component } = await importComponent()

			this.setState({ component })
		}

		render() {
			const C = this.state.component

			return C ? <C {...this.props} /> : null
		}
	}

	return lazyRequire
}
