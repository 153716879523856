import React from 'react'
import Routes from './routers'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { Bridge, SDK, UtilDevice } from '@iskytrip/sdk'
import { env } from './env.config'

// 全局CSS样式
import './index.css'

const envConfig = require('./env.config.js')
const isSentry = require('../project.config.js').isSentry

SDK.init(envConfig.env)

if (isSentry === env || isSentry === true) {
	Sentry.init({
		environment: envConfig.env,
		// release: envConfig.commit,
		dsn: 'https://3627073f43a943fba2a5de84b131c56c@onsentry.iskytrip.com/3',
		integrations: [new Integrations.RewriteFrames(), new Integrations.ExtraErrorData({ depth: 6 })],
	})
}
// 解决键盘弹出后挡表单的问题
Bridge.closeAndroidKeyboard(({ height, status }) => {
	if (
		status === 1 &&
		(document.activeElement.tagName.toUpperCase() === 'INPUT' ||
			document.activeElement.tagName.toUpperCase() === 'TEXTAREA')
	) {
		window.setTimeout(() => {
			if ('scrollIntoView' in document.activeElement) {
				document.activeElement.scrollIntoView()
			} else {
				document.activeElement.scrollIntoViewIfNeeded()
			}
		}, 50)
	}
})

try {
	if (UtilDevice.isApp()) {
		Bridge.appGetToken((_, userId) => {
			if (userId) {
				window.gio('setUserId', userId)
			} else {
				window.gio('clearUserId')
			}
		})
	} else {
		window.gio('clearUserId')
	}
} catch (e) {
	console.error(e)
}

ReactDOM.render(<Routes />, document.getElementById('root'))
