import React from 'react'
import lazyRequire from './LazyRequire'
import { Route, BrowserRouter, Switch } from 'react-router-dom'

const Test = lazyRequire(() => import('./pages/demo/test'))
const Demo = lazyRequire(() => import('./pages/demo/demo'))
const Next1 = lazyRequire(() => import('./pages/demo/next1'))
const NextDemo = lazyRequire(() => import('./pages/demo/nextDemo'))
const redPackageAct = lazyRequire(() => import('./pages/redPackageAct/index')) //红包活动
const ServiceProtocol = lazyRequire(() => import('./pages/protocol/serviceProtocol')) // 在机场服务协议
const PrivacyPolicy = lazyRequire(() => import('./pages/protocol/privacyPolicy')) // 隐私政策
const HelpCoupon = lazyRequire(() => import('./pages/helpCoupon')) // 助力券
const HelpCouponRule = lazyRequire(() => import('./pages/helpCoupon/rule')) // 助力券规则
const GoodsReservation = lazyRequire(() => import('./pages/goodsReservation'))
const OpenMiniApp = lazyRequire(() => import('./pages/loadingPage/miniApp'))

// 建行无界卡办理
const CcbIndex = lazyRequire(() => import('./pages/ccb/index'))
const CcbExplain = lazyRequire(() => import('./pages/ccb/explain'))

export default () => (
	<BrowserRouter>
		<Switch>
			<Route path='/test' component={Test} />
			<Route path='/demo' component={Demo} />
			<Route path='/next1' component={Next1} />
			<Route path='/next' component={NextDemo} />
			<Route path='/red-pack-act' component={redPackageAct} />
			<Route path='/serviceProtocol' component={ServiceProtocol} />
			<Route path='/privacyPolicy' component={PrivacyPolicy} />
			<Route path='/helpCoupon' component={HelpCoupon} />
			<Route path='/helpCouponRule' component={HelpCouponRule} />
			<Route path='/openMiniApp' component={OpenMiniApp} />
			<Route path='/goods-reservation' component={GoodsReservation} />

			<Route path='/ccb/index' component={CcbIndex} />
			<Route path='/ccb/explain' component={CcbExplain} />
		</Switch>
	</BrowserRouter>
)
